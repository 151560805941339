<template>
  <div>
    <b-row>
      <b-col lg="12" sm="12" class="mb-2" v-if="show_alert_permission">
        <b-alert
      
          show
          dismissible
          fade
          class="mb-0"
          variant="danger"
        >
          <div class="alert-body">
            <span>{{
              $t(
                "Pages.Studio.Title.to_use_all_services"
              )
            }}</span>
          </div>
        </b-alert>
      </b-col>
      <b-col lg="12" sm="12" class="mb-2" v-if="show_alert_get_list_playlist">
        <b-alert
          show
          dismissible
          fade
          class="mb-0"
          variant="warning"
        >
          <div class="alert-body">
            <span>{{
              $t(
                "Pages.Studio.Title.wait_receiving_playlist"
              )
            }}</span>
          </div>
        </b-alert>
      </b-col>

      <b-col lg="12" v-if="show">
        <b-alert
          show
          dismissible
          fade
          class="mb-2"
          variant="success"
        >
          <div class="alert-body">
            <span>{{ $t("Pages.Studio.Title.please_wait") }}</span>
          </div>
        </b-alert>
      </b-col>
      <b-col cols="12">
        <b-overlay
          :show="show_alert_get_list_playlist"
          spinner-variant="primary"
          variant="transparent"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <b-row class="d-flex justify-content-around text-center">
            <b-col lg="12" sm="12">
              <b-card class="col-12">
                <b-card-body>
                  <b-row>
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        @keyup="getList"
                        v-model="filters.q"
                        :placeholder="
                          $t('Pages.Studio.Placeholder.search_playlist')
                        "
                        class="search-product"
                      />
                      <b-input-group-append is-text>
                        <feather-icon icon="SearchIcon" class="text-muted" />
                      </b-input-group-append>
                    </b-input-group>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-overlay>
      </b-col>
      <b-col lg="12" sm="12">
        <b-card no-body class="card-company-table">
          <b-overlay
            :show="show_alert_get_list_playlist"
            spinner-variant="primary"
            variant="transparent"
            spinner-type="grow"
            spinner-small
            rounded="sm"
          >
            <b-table
              :items="tableData"
              responsive
              :fields="fields"
              primary-key="id"
            
              show-empty
              :empty-text="$t('Pages.Studio.Table.no_playlist_found')"
              :sort-desc.sync="isSortDirDesc"
              class="position-relative"
            >
              <template #cell(title)="data">
                <div class="d-flex align-items-center">
                  <b-avatar rounded size="32" variant="light-company">
                    <b-img
                      class="img-fluid"
                      :src="data.item.thumbnail"
                      alt="avatar img"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <div class="font-weight-bolder">
                      {{ data.item.title }}
                    </div>
                    <div class="font-small-2 text-muted">
                      {{ data.item.description.slice(0, 255) }}
                    </div>
                  </div>
                </div>
              </template>

              <template #cell(publishedAt)="data">
                <div class="d-flex flex-column">
                  <span class="font-weight-bolder mb-25">{{
                    MyDate.getFormatDate(data.item.published_at)
                  }}</span>
                </div>
              </template>

              <template #cell(privacy_status)="data">
                <div class="d-flex flex-column">
                  <span>
                    <b-badge
                      pill
                      variant="light-primary"
                      v-if="data.item.privacy_status == 'public'"
                      >{{ $t("Pages.Studio.Table.Public") }}</b-badge
                    >
                    <b-badge
                      pill
                      variant="light-primary"
                      v-if="data.item.privacy_status == 'private'"
                      >{{ $t("Pages.Studio.Table.Private") }}</b-badge
                    >
                    <b-badge
                      pill
                      variant="light-primary"
                      v-if="data.item.privacy_status == 'unlisted'"
                      >{{ $t("Pages.Studio.Table.Unlisted") }}</b-badge
                    >
                  </span>
                </div>
              </template>

              <template #cell(actions)="data">
                <div class="d-flex align-items-center">
                  <span
                    @click="
                      OpenModalUpdatePlaylist(
                        data.item.title,
                        data.item.description,
                        data.item.playlist_id
                      )
                    "
                  >
                    <feather-icon
                      icon="EditIcon"
                      size="16"
                      class="mx-1 cursor-pointer"
                      v-b-modal.modal-login
                    />
                  </span>
                  <span @click="deletePlaylist(data.item.playlist_id)">
                    <feather-icon
                      icon="TrashIcon"
                      size="16"
                      class="mx-1 cursor-pointer text-danger"
                    />
                  </span>
                </div>
              </template>
            </b-table>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" v-if="pages > 1">
        <b-pagination-nav
          :link-gen="linkGen"
          :number-of-pages="pages"
          use-router
          class="mb-0"
        />
      </b-col>
    </b-row>
    <!--Update playlist-->
    <b-modal
      id="modal-login"
      cancel-variant="outline-secondary"
      :ok-title="formUpdate.btn"
      cancel-title="لغو"
      centered
      :title="formUpdate.titleForm"
      @ok="UpdatePlaylist"
    >
      <b-form>
        <b-form-group>
          <label for="title">{{ this.$t("Pages.Studio.Label.Title") }}:</label>
          <div>
            <b-form-input
              maxlength="150"
              id="title"
              type="text"
              v-model="formUpdate.title"
              required
            />
            <small class="mt-2">
              {{ $t("Pages.Studio.Title.Characters") }}: 150 /
              {{ formUpdate.title.length }}
            </small>
          </div>
        </b-form-group>
        <b-form-group>
          <label for="description"
            >{{ this.$t("Pages.Studio.Label.Description") }}:</label
          >
          <div>
            <b-form-textarea
              maxlength="5000"
              id="description"
              type="text"
              v-model="formUpdate.description"
              rows="8"
            />
            <small class="mt-2">
              {{ $t("Pages.Studio.Title.Characters") }}: 5000 /
              {{ formUpdate.title.length }}
            </small>
          </div>
        </b-form-group>
        <b-form-group label-for="vue-select">
          <label class="mt-1" for="description"
            >{{ this.$t("Pages.Studio.Label.Status") }}:</label
          >
          <v-select
            id="vue-select"
            v-model="formUpdate.privacyStatus"
            :dir="
              () => (localStorage.getItem('language') == 'fa' ? 'rtl' : 'ltr')
            "
            :options="option"
          />
        </b-form-group>
      </b-form>
    </b-modal>
    <!--Update playlist-->

    <!--Create playlist-->
    <b-modal
      id="modal-login1"
      cancel-variant="outline-secondary"
      :ok-title="formCreate.btn"
      cancel-title="لغو"
      centered
      :title="formCreate.titleForm"
      @ok="CreatePlaylist"
    >
      <b-form>
        <b-form-group>
          <label for="title">{{ this.$t("Pages.Studio.Label.Title") }}:</label>
          <div>
            <b-form-input
              maxlength="150"
              id="title"
              type="text"
              v-model="formCreate.title"
              required
            />
            <small class="mt-2">
              {{ $t("Pages.Studio.Title.Characters") }}: 150 /
              {{ formCreate.title.length }}
            </small>
          </div>
        </b-form-group>
        <b-form-group>
          <label for="description"
            >{{ this.$t("Pages.Studio.Label.Description") }}:</label
          >
          <div>
            <b-form-textarea
              maxlength="5000"
              id="description"
              type="text"
              v-model="formCreate.description"
              rows="8"
            />
            <small class="mt-2">
              {{ $t("Pages.Studio.Title.Characters") }}: 5000 /
              {{ formCreate.description.length }}
            </small>
          </div>
        </b-form-group>
        <b-form-group label-for="vue-select">
          <label class="mt-1" for="description"
            >{{ this.$t("Pages.Studio.Label.Status") }}:</label
          >
          <v-select
            id="vue-select"
            v-model="formCreate.privacyStatus"
            :dir="
              () => (localStorage.getItem('language') == 'fa' ? 'rtl' : 'ltr')
            "
            :options="option"
          />
        </b-form-group>
      </b-form>
    </b-modal>
    <!--Create playlist-->
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BAvatar,
  BTable,
  BImg,
  BAlert,
  BBadge,
  BButton,
  BLink,
  BTooltip,
  BMedia,
  BMediaAside,
  BMediaBody,
  BInputGroup,
  BInputGroupAppend,
  BCardHeader,
  BCardBody,
  BOverlay,
  BPaginationNav,
  BFormInput,
  BModal,
  VBModal,
  BFormTextarea,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import vSelect from "vue-select";
import MyDate from "@core/components/date/MyDate.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardBody,
    BCardText,
    BRow,
    BCol,
    BAvatar,
    BTable,
    BImg,
    BLink,
    BButton,
    BAlert,
    BBadge,
    BTooltip,
    BCardHeader,
    vSelect,
    BOverlay,
    BPaginationNav,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BModal,
    BFormTextarea,
  },
  data() {
    return {
      show: false,
      MyDate,
      tableData: [],
      pages: 0,
      fields: [
        { key: "title", label: this.$t("Pages.Studio.Table.title_playlist") },
        {
          key: "privacy_status",
          label: `${this.$t("Pages.Studio.Table.privacy_status")}`,
        },
        {
          key: "publishedAt",
          label: `${this.$t("Pages.Studio.Table.publishedAt_playlist")}`,
        },
        { key: "actions", label: this.$t("Pages.Studio.Table.Operation") },
      ],
      isSortDirDesc: true,
      filters: {
        q: "",
      },
      titleModel: this.$t("Pages.Studio.Title.edit_playlist"),
      formUpdate: {
        title: "",
        description: "",
        id: 0,
        privacyStatus: "public",
        btn: this.$t("Pages.Studio.Button.btn_update_playlist"),
        titleForm: this.$t("Pages.Studio.Title.title_model_update_playlist"),
      },
      option: ["public", "private", "unlisted"],
      formCreate: {
        title: "",
        description: "",
        privacyStatus: "public",
        btn: this.$t("Pages.Studio.Button.btn_create_playlist"),
        titleForm: this.$t("Pages.Studio.Title.title_model_update_playlist"),
      },
      show_alert_permission: false,
      show_alert_get_list_playlist: true,
      PermissionCreatePlaylist: false,
      PermissionUpdatePlayList: false,
      PermissionDeletePlayList: false,
      count_coin: 0,
      details: {},
      first_request: true,
    };
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  created() {
    this.getCountCoin();
    this.getUseDetails();
    this.checkPermissionYoutubeSSL();
    this.$http.get("/api/check_login").then(({ data }) => {
      let permissions = [];
      if (data.permissions.length != 0) {
        data.permissions.map((item) => {
          permissions.push(item.name);
        });
      }
      if (permissions.includes("Create PlayList")) {
        this.PermissionCreatePlaylist = true;
      }
      if (permissions.includes("Update PlayList")) {
        this.PermissionUpdatePlayList = true;
      }
      if (permissions.includes("Delete PlayList")) {
        this.PermissionDeletePlayList = true;
      }
      this.getList();
    });
  },
  watch: {
    $route(to, from) {
      if (to.query != from.query) {
        this.getList();
      }
    },
  },
  methods: {
    getUseDetails() {
      this.$http
        .get(`/api/channel/studio/playlists/use-details`)
        .then((response) => {
          this.details = response.data;
        });
    },
    getCountCoin() {
      this.$http.get(`/api/dashboard/getCountCoin`).then((response) => {
        this.count_coin = response.data.count_coin;
      });
    },
    openYoutube(channelId) {
      window.open(`https://www.youtube.com/channel/${channelId}`, "_blank");
    },
    getList() {
      let page =
        typeof this.$route.query.page !== "undefined"
          ? this.$route.query.page
          : 1;
      let text = this.filters.q == "" ? "" : this.filters.q;
      if (this.first_request) {
        this.$http
          .get(
            `/api/channel/studio/playlists/update-list?channel_id=${this.$route.params.channel_id}`
          )
          .then((response) => {
            if (response.data.status || response.data.status == "true") {
              this.show_alert_get_list_playlist = false;
              this.$http
                .get(`/api/channel/studio/playlists/getList?page=${page}`, {
                  params: {
                    channel_id: this.$route.params.channel_id,
                    title: text,
                  },
                })
                .then((response) => {
                  this.tableData = response.data.data;
                  this.pages = response.data.last_page;
                });
            }
          });
        this.first_request = false;
      } else {
        this.$http
          .get(`/api/channel/studio/playlists/getList?page=${page}`, {
            params: {
              channel_id: this.$route.params.channel_id,
              title: text,
            },
          })
          .then((response) => {
            this.tableData = response.data.data;
            this.pages = response.data.last_page;
          });
      }
    },
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    checkPermissionYoutubeSSL() {
      this.$http
        .get(`/api/channel/studio/checkPermission`, {
          params: {
            channel_id: this.$route.params.channel_id,
          },
        })
        .then((res) => {
          if (res.data.success == false) {
            this.show_alert_permission = true;
          } else {
            this.show_alert_permission = false;
          }
        });
    },
    OpenModalUpdatePlaylist(title, description, id) {
      this.formUpdate.title = title;
      this.formUpdate.description = description;
      this.formUpdate.id = id;
    },
    OpenModalCreatePlaylist() {
      ///
    },
    UpdatePlaylist() {
      if (this.PermissionUpdatePlayList) {
        if (this.formUpdate.title != "" && this.formUpdate.description != "") {
          if (this.count_coin > 1 || this.details.update < 2) {
            this.$http
              .get(`/api/channel/studio/checkPermission`, {
                params: {
                  channel_id: this.$route.params.channel_id,
                },
              })
              .then((res) => {
                if (res.data.success) {
                  this.show = true;
                  this.$http
                    .post(`/api/channel/studio/playlists/update`, {
                      channel_id: this.$route.params.channel_id,
                      title: this.formUpdate.title,
                      description: this.formUpdate.description,
                      privacy_status: this.formUpdate.privacyStatus,
                      playlist_id: this.formUpdate.id,
                    })
                    .then((response) => {
                      this.show = false;
                      this.makeToast(
                        this.$t("Base.Alert.Successful"),
                        this.$t(
                          "Pages.Studio.Alert.success_update_playlist"
                        ),
                        "success"
                      );
                      this.getList();
                    })
                    .catch((error) => {
                      if (error.response.status === 500) {
                        this.makeToast(
                          this.$t("Base.Alert.Error"),
                          this.$t(
                            "Pages.Studio.Alert.error_while_confirm"
                          ),
                          "danger"
                        );
                      }
                    });
                } else {
                  this.makeToast(
                    this.$t("Base.Alert.Error"),
                    this.$t(
                      "Pages.Studio.Alert.ch_access_incomplete"
                    ),
                    "danger"
                  );
                }
              });
          } else {
            this.$swal({
              title: this.$t("Base.Alert.Error"),
              text: this.$t(
                "Base.Alert.not_enough_coin"
              ),
              icon: "warning",
              confirmButtonText: this.$t("Pages.MasterFile.Title.Buy"),
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                this.$router.push({ name: "MasterCoin" }).catch(() => {});
              }
            });
          }
        } else {
          this.makeToast(
            this.$t("Base.Alert.Error"),
            this.$t("Pages.Studio.Alert.care_fill_info"),
            "danger"
          );
        }
      } else {
        this.openPermissionDenid();
      }
    },
    CreatePlaylist() {
      if (this.PermissionCreatePlaylist) {
        if (this.formCreate.title != "" && this.formCreate.description != "") {
          if (this.count_coin > 1 || this.details.create < 2) {
            this.$http
              .get(`/api/channel/studio/checkPermission`, {
                params: {
                  channel_id: this.$route.params.channel_id,
                },
              })
              .then((res) => {
                if (res.data.success) {
                  this.show = true;
                  this.$http
                    .post(`/api/channel/studio/playlists/create`, {
                      channel_id: this.$route.params.channel_id,
                      title: this.formCreate.title,
                      description: this.formCreate.description,
                      privacy_status: this.formCreate.privacyStatus,
                    })
                    .then((response) => {
                      this.show = false;
                      this.makeToast(
                        this.$t("Base.Alert.Successful"),
                        this.$t(
                          "Pages.Studio.Alert.success_create_playlist"
                        ),
                        "success"
                      );
                      this.formCreate.title = "";
                      this.formCreate.description = "";
                      this.getList();
                    })
                    .catch((error) => {
                      if (error.response.status === 500) {
                        this.makeToast(
                          this.$t("Base.Alert.Error"),
                          this.$t(
                            "Pages.Studio.Alert.error_while_confirm"
                          ),
                          "danger"
                        );
                      }
                    });
                } else {
                  this.makeToast(
                    this.$t("Base.Alert.Error"),
                    this.$t(
                      "Pages.Studio.Alert.ch_access_incomplete"
                    ),
                    "danger"
                  );
                }
              });
          } else {
            this.$swal({
              title: this.$t("Base.Alert.Error"),
              text: this.$t(
                "Base.Alert.not_enough_coin"
              ),
              icon: "warning",
              confirmButtonText: this.$t("Pages.MasterFile.Title.Buy"),
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                this.$router.push({ name: "MasterCoin" }).catch(() => {});
              }
            });
          }
        } else {
          this.makeToast(
            this.$t("Base.Alert.Error"),
            this.$t("Pages.Studio.Alert.care_fill_info"),
            "danger"
          );
        }
      } else {
        this.openPermissionDenid();
      }
    },
    deletePlaylist(playlist_id) {
      if (this.PermissionDeletePlayList) {
        if (this.count_coin > 1 || this.details.delete < 2) {
          this.$swal({
            title: this.$t("Base.Alert.are_sure"),
            text: this.$t("Base.Alert.wont_able_revert"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: this.$t("Base.Alert.del_it"),
            cancelButtonText: this.$t("Base.Alert.Cancel"),
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              this.$http
                .post(`/api/channel/studio/playlists/delete`, {
                  channel_id: this.$route.params.channel_id,
                  playlist_id: playlist_id,
                })
                .then((response) => {
                  this.show = false;
                  this.$swal({
                    icon: "success",
                    title: this.$t("Base.Alert.Deleted!"),
                    text: this.$t("Base.Alert.removed_success"),
                    customClass: {
                      confirmButton: "btn btn-success",
                    },
                  });
                  this.getList();
                });
            }
          });
        } else {
          this.$swal({
            title: this.$t("Base.Alert.Error"),
            text: this.$t(
              "Base.Alert.not_enough_coin"
            ),
            icon: "warning",
            confirmButtonText: this.$t("Pages.MasterFile.Title.Buy"),
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              this.$router.push({ name: "MasterCoin" }).catch(() => {});
            }
          });
        }
      } else {
        this.openPermissionDenid();
      }
    },
    openPermissionDenid() {
      this.$swal({
        title: this.$t("Base.Alert.Error"),
        text: this.$t(
          "Navbar.Title.need_golden"
        ),
        icon: "warning",
        confirmButtonText: this.$t("Navbar.Button.upgrade_account"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$router.push("/pricing").catch(() => {});
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
